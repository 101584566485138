.navigationWrapper {
    width: calc(100% - 280px);
    background-color: #f4f4f4;
}

.mainContent {
    padding: 2rem;
    height: calc(100% - 266px);
    overflow-y: scroll;
}

.footer {
    height: 30px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}
